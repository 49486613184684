import { APIRoute } from '@app/config'
import { getStoredLanguageCode } from '@app/localization'
import { GameLeaderboard } from '@golfgamebook/web-leaderboard'
import '@golfgamebook/web-leaderboard/dist/css/style.css'
import { useSelector } from 'react-redux'
import { getActiveRoundNumber } from '@app/utils/tournamentUtils'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useRef } from 'react'

/**
 * Override the default styles of the web-leaderboard library,
 * to use it as TV leaderboard.
 */

const useStyles = makeStyles(() => ({
  root: {
    '& .leaderboard-container': {
      boxShadow: 'none',
    },

    '& .overflow-auto': {
      maxHeight: 'none',
      overflowY: 'visible',
    },

    '& .score-table': {
      borderSpacing: '0 0.1875em',
      borderCollapse: 'separate',

      '& thead': {
        borderRadius: '3px',
      },

      '&>thead th': {
        fontSize: '1.125em',
        fontWeight: 700,
      },

      '& tr': {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 2px 0px',
      },

      '& td:first-child, & th:first-child': {
        borderRadius: '3px 0 0 3px',
      },

      '& td:last-child, & th:last-child': {
        borderRadius: '0 3px 3px 0',
      },

      '& .player-name': {
        fontSize: '1.125em',
        lineHeight: '1.2',
      },
    },
  },
}))

type Props = {
  autoScroll?: boolean
  autoScrollSpeed?: number
  forceGameType?: 'primary' | 'secondary' | 'scratch'
}

export default function GameWebLeaderboard({ autoScroll, autoScrollSpeed, forceGameType }: Props): JSX.Element {
  const classes = useStyles()
  const { tournamentSite, activeDivisionEndpoint } = useSelector((store: StoreState) => store.tournamentReducer)
  const { tournament } = tournamentSite
  const { id: tournamentId } = tournament
  const scrolling = useRef(autoScroll)
  const scrollingSpeed = useRef(autoScrollSpeed)

  let leaderboardUrl = ''
  if (activeDivisionEndpoint === '') {
    leaderboardUrl = APIRoute.GET_LEADERBOARD(tournamentId)
  } else {
    leaderboardUrl = activeDivisionEndpoint
  }

  const startScrollAnimation = () => {
    const container = document.getElementById('scrollable-container')
    if (!container) return

    scrolling.current = true

    const maxOffset = container.scrollHeight - container.clientHeight

    container.scrollTo(0, 0)

    let heightOffset = 0
    let scrollStarted = false
    let reachedBottom = false
    let intervalBase = 1000

    // Could be optimized like https://gist.github.com/elundmark/38d3596a883521cb24f5
    // Map: (pixels to scroll per frame)
    const fps = 40
    const scrollingSpeedHeightMap = new Map<number, number>()
    scrollingSpeedHeightMap.set(1, 0.5)
    scrollingSpeedHeightMap.set(2, 1)
    scrollingSpeedHeightMap.set(3, 2)

    const animate = () => {
      if (heightOffset < maxOffset) {
        // Keep the scroll stopped for 2.5 seconds when we are at the top of the leaderboard
        intervalBase = heightOffset === 0 && !scrollStarted ? fps * 2500 : 1000
        heightOffset += scrollingSpeedHeightMap.get(scrollingSpeed.current)!
        scrollStarted = true
      }

      if (heightOffset >= maxOffset) {
        if (!reachedBottom) {
          reachedBottom = true
          // Keep the scroll stopped for 1.5 second when we reached the end of the leaderboard
          intervalBase = fps * 1500
        } else {
          heightOffset = 0
          reachedBottom = false
        }
      }

      container.scrollTo({ top: heightOffset, behavior: 'smooth' })

      if (scrolling.current) {
        setTimeout(() => {
          requestAnimationFrame(animate)
        }, intervalBase / fps)
      }
    }

    if (maxOffset > 0) {
      animate()
    }
  }

  useEffect(() => {
    scrollingSpeed.current = autoScrollSpeed
    if (!scrolling.current && autoScroll) {
      startScrollAnimation()
    } else {
      scrolling.current = autoScroll
    }
  }, [autoScroll, autoScrollSpeed])

  return (
    <div className={classes.root}>
      <GameLeaderboard
        endpoint={leaderboardUrl}
        forceGameType={forceGameType}
        theme={tournamentSite.colorTheme.toLowerCase()}
        showFilters={false}
        showCountryFlags={tournamentSite.countryFlagForPlayersEnabled}
        language={getStoredLanguageCode()}
        refreshInterval={30000}
        activeRound={getActiveRoundNumber(tournament.rounds)}
        showHeader={false}
        enableToggleScoreSheets={false}
        units={tournamentSite.units}
      />
    </div>
  )
}
