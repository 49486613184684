import Typography from '@mui/material/Typography'
import React from 'react'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Theme,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import GameOptions from './ui/GameOptions'
import { FormattedMessage } from 'react-intl'
import { formTourSiteUrl } from '../../config'
import { rem } from '../../theme/materialUITheme'
import TourButton from '../ui/TourButton'
import { GameFormats } from '../../utils/enums'
import classNames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    tableContainer: {
      overflow: 'auto',
      padding: '0 20px',
      maxHeight: 220,
      maxWidth: 320,
      margin: '0 auto',
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.4em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey',
        },
      },
    },
    table: {
      marginTop: 5,
      '& th, & td': {
        padding: 4,
        textAlign: 'center',
        width: '50%',
        fontSize: rem(16),
      },
      '& th': {
        fontWeight: 500,
        color: theme.palette.primary.main,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
      '& td': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
    subtitle: {
      fontStyle: 'normal',
      color: theme.customPalette.siteNavigationText,
      [theme.breakpoints.up('md')]: {
        marginLeft: -20,
      },
    },
    tour: {
      color: theme.palette.primary.main,
      fontSize: rem(18),
      fontWeight: 600,
      textDecoration: 'none',
    },
    ranking: {
      textAlign: 'center',
      fontSize: rem(20),
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    rankingLogo: {
      maxWidth: 200,
      maxHeight: 80,
      margin: '10px auto',
      display: 'block',
    },
    info: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    gameDetails: {
      justifyContent: 'space-around',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    title: {
      marginBottom: 16,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    contentContainer: {
      marginLeft: 20,
      width: 250,
      [theme.breakpoints.up('md')]: {
        margin: 'auto',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        width: '100%',
      },
    },
    contestContainer: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    tieBreak: {
      paddingRight: 10,
    },
    gameDetailsTitle: {
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
    },
    tourContainer: {
      justifyContent: 'center',
    },
    primaryGameTitle: {
      color: theme.customPalette.siteNavigationText,
      fontStyle: 'normal',
      [theme.breakpoints.up('md')]: {
        marginLeft: -20,
      },
    },
    gameTitle: {
      width: 250,
      [theme.breakpoints.up('md')]: {
        margin: 'auto',
      },
    },
  })

interface OwnProps {
  round: number
  tournamentSite?: TournamentSite
}

type Props = OwnProps & WithStyles<typeof styles>

const GameInfo = ({ round, tournamentSite, classes }: Props) => {
  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'))
  const roundData = tournamentSite.tournament.rounds[round]

  if (!roundData) {
    return null
  }

  const firstRound = tournamentSite.tournament.rounds[0]

  const renderPrimaryTieBreakMethod = (gameType: 'primary' | 'side') => {
    const { primaryTieBreakMethod } = gameType === 'primary' ? roundData.primaryGameOptions : roundData.sideGameOptions

    if (primaryTieBreakMethod) {
      return (
        <tbody>
          <tr>
            <td style={{ paddingRight: 10 }}>
              <Typography variant={'body1'}>
                <FormattedMessage id={'strings.tieBreakMethod'} />:
              </Typography>
            </td>
            <td>
              <Typography variant={'body1'}>
                <b>
                  <FormattedMessage id={`strings.tieBreakMethod_${primaryTieBreakMethod}`} />
                </b>
              </Typography>
            </td>
          </tr>
        </tbody>
      )
    } else {
      return null
    }
  }

  const justifyContent = mediaQuery ? 'flex-start' : 'center'
  const sideGame = roundData.sideGameEnabled && roundData.sideGame.id !== GameFormats.NO_SIDE

  // TODO: adapt to multiround?
  return (
    <Grid container rowSpacing={{ xs: 2, md: 3 }}>
      <Grid container item xs={12} justifyContent={justifyContent} className={classes.gameDetailsTitle}>
        <Typography variant={'h2'}>
          <FormattedMessage id={'strings.gameDetails'} />
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <div className={classes.gameTitle}>
          <Typography variant="h4" className={classes.primaryGameTitle}>
            <FormattedMessage id={'strings.primaryGame'} />
          </Typography>
        </div>
        <table className={classes.contentContainer}>
          <GameOptions gameOptions={roundData.primaryGameOptions} gameFormatId={roundData.primaryGame.id} />
          {renderPrimaryTieBreakMethod('primary')}
        </table>
      </Grid>

      {sideGame && (
        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.gameTitle}>
            <Typography variant="h4" className={classes.subtitle}>
              <FormattedMessage id={'strings.sideGame'} />
            </Typography>
          </div>
          <table className={classes.contentContainer}>
            <GameOptions gameOptions={roundData.sideGameOptions} gameFormatId={roundData.sideGame.id} />
            {renderPrimaryTieBreakMethod('side')}
          </table>
        </Grid>
      )}

      {roundData.contestsEnabled && (
        <Grid item xs={12} sm={6}>
          <div className={classes.gameTitle}>
            <Typography variant="h4" className={classes.subtitle}>
              <FormattedMessage id={'strings.contests'} />
            </Typography>
          </div>
          <table className={classNames([classes.contentContainer, classes.contestContainer])}>
            <tbody>
              {roundData.contestOptions.closestToPin.length > 0 && (
                <tr>
                  <td style={{ paddingRight: 10, width: 170 }}>
                    <Typography variant="body1">
                      <FormattedMessage id="strings.closestToPinHoles" />:
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1">
                      <strong>
                        {roundData.contestOptions.closestToPin
                          .slice()
                          .sort(function (a, b) {
                            return String(a).localeCompare(String(b), undefined, {
                              numeric: true,
                              sensitivity: 'base',
                            })
                          })
                          .join(', ')}
                      </strong>
                    </Typography>
                  </td>
                </tr>
              )}
              {roundData.contestOptions.longestDrive.length > 0 && (
                <tr>
                  <td style={{ paddingRight: 10 }}>
                    <Typography variant="body1">
                      <FormattedMessage id="strings.longestDriveHoles" />:
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1">
                      <strong>
                        {roundData.contestOptions.longestDrive
                          .sort(function (a, b) {
                            return String(a).localeCompare(String(b), undefined, {
                              numeric: true,
                              sensitivity: 'base',
                            })
                          })
                          .join(', ')}
                      </strong>
                    </Typography>
                  </td>
                </tr>
              )}
              {roundData.contestOptions.straightestDrive.length > 0 && (
                <tr>
                  <td style={{ paddingRight: 10 }}>
                    <Typography variant="body1">
                      <FormattedMessage id="strings.straightestDriveHoles" />:
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1">
                      <strong>
                        {roundData.contestOptions.straightestDrive
                          .sort(function (a, b) {
                            return String(a).localeCompare(String(b), undefined, {
                              numeric: true,
                              sensitivity: 'base',
                            })
                          })
                          .join(', ')}
                      </strong>
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Grid>
      )}

      {tournamentSite.tournament.tourCondensed && (
        <>
          <Divider style={{ width: '100%', margin: '30px 0' }} />

          <Grid container item xs={12} className={classes.tourContainer}>
            <Typography variant={'h2'} style={{ textAlign: 'center', marginBottom: 16 }}>
              <FormattedMessage id={'strings.tour'} />
            </Typography>

            <Grid container style={{ justifyContent: 'center' }}>
              {tournamentSite.tournament.tourCondensed.logo && (
                <Grid item xs={12} sm={6} style={{ textAlign: 'center', marginBottom: 10 }}>
                  <img
                    style={{ maxWidth: '100%', maxHeight: 115 }}
                    src={tournamentSite.tournament.tourCondensed.logo.url}
                    alt=""
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} style={{ textAlign: 'center', marginBottom: 10 }}>
                <Typography variant="body1">
                  <FormattedMessage id="strings.partOfTour" />
                  <br />
                  <a href={formTourSiteUrl(tournamentSite.tournament.tourCondensed.id)} className={classes.tour}>
                    {tournamentSite.tournament.tourCondensed.name}
                  </a>
                </Typography>

                <a
                  href={formTourSiteUrl(tournamentSite.tournament.tourCondensed.id)}
                  style={{ textDecoration: 'none' }}
                >
                  <TourButton
                    buttonProps={{
                      style: { marginTop: 30 },
                    }}
                  >
                    <FormattedMessage id={'buttons.viewTourSite'} />
                  </TourButton>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {(firstRound.primaryRankingConfig || firstRound.sideRankingConfig) && (
        <>
          <Divider style={{ width: '100%', margin: '30px 0' }} />

          <Grid item xs={12}>
            <Typography variant={'h2'} style={{ textAlign: 'center', marginBottom: 16 }}>
              <FormattedMessage id={'strings.ranking'} />
            </Typography>
            <Typography variant="body1" style={{ textAlign: 'center', marginTop: 5, marginBottom: 20 }}>
              <FormattedMessage id="strings.tournamentRankings" />
            </Typography>
            <Grid container spacing={2}>
              {firstRound.primaryRankingName && (
                <Grid item xs={12} sm={firstRound.sideRankingName ? 6 : 12}>
                  <Typography
                    variant="body1"
                    style={{ textAlign: 'center', fontSize: rem(18), fontStyle: 'italic', fontWeight: 500 }}
                  >
                    <FormattedMessage id="strings.primaryGame" />
                  </Typography>
                  {firstRound.primaryRankingLogo && (
                    <img src={firstRound.primaryRankingLogo.url} className={classes.rankingLogo} alt="" />
                  )}
                  <Typography variant="body1" className={classes.ranking}>
                    <strong>{firstRound.primaryRankingName}</strong>
                  </Typography>

                  <div className={classes.tableContainer}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <FormattedMessage id="strings.position" />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id="strings.points" />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {firstRound.primaryRankingConfig.map((r, i) => (
                          <TableRow key={i}>
                            <TableCell>{r.position}</TableCell>
                            <TableCell>{r.points}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
              )}
              {firstRound.sideRankingConfig && (
                <Grid item xs={12} sm={firstRound.primaryRankingName ? 6 : 12}>
                  <Typography
                    variant="body1"
                    style={{ textAlign: 'center', fontSize: rem(18), fontStyle: 'italic', fontWeight: 500 }}
                  >
                    <FormattedMessage id="strings.sideGame" />
                  </Typography>
                  {firstRound.sideRankingLogo && (
                    <img src={firstRound.sideRankingLogo.url} className={classes.rankingLogo} alt="" />
                  )}
                  <Typography variant="body1" className={classes.ranking}>
                    <strong>{firstRound.sideRankingName}</strong>
                  </Typography>

                  <div className={classes.tableContainer}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <FormattedMessage id="strings.position" />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id="strings.points" />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {firstRound.sideRankingConfig.map((r, i) => (
                          <TableRow key={i}>
                            <TableCell>{r.position}</TableCell>
                            <TableCell>{r.points}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default withStyles(styles)(
  connect((state: StoreState) => ({
    tournamentSite: state.tournamentReducer.tournamentSite,
  }))(GameInfo),
)
