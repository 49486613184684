import React, { ChangeEvent, useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Tab, Tabs, Theme, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { rem } from '@app/theme/materialUITheme'
import { InfoPage } from '@app/scenes/tournament-site/tournament-info-page'
import { GamePage } from '@app/scenes/tournament-site/tournament-game-page/Game'
import { PlayersPage } from '@app/scenes/tournament-site/tournament-players-page'
import { StartListPage } from '@app/scenes/tournament-site/tournament-start-list-page'
import { ContestsPage } from '@app/scenes/tournament-site/tournament-contests-page'
import { LeaderboardPage } from '@app/scenes/tournament-site/tournament-leaderboard-page'
import { ResultsPage } from '@app/scenes/tournament-site/tournament-results-page'
import { SummaryPage } from '@app/scenes/tournament-site/tournament-summary-page'
import TourInfo from '@app/components/tour/TourInfo'
import TourEvents from '@app/components/tour/TourEvents'
import TourRankings from '@app/components/tour/TourRankings'
import { useDispatch, useSelector } from 'react-redux'
import { EntryMethod, TabsEnum, TournamentTypes } from '@app/utils/enums'
import { setCurrentPage } from '@app/store/pages/actions'
import { useSearchParams } from 'react-router-dom'
import { useLeaderboardHasScores } from '@golfgamebook/web-leaderboard'
import { APIRoute } from '@app/config'

interface ITabs {
  title: React.ReactNode
  component: React.ReactNode
  value: TabsEnum
  hideOnWeeklyTournament?: boolean
  hideOnMultiRounds?: boolean
}

enum UrlParamOptions {
  PREVIEW = 'preview',
}

enum PreviewOptions {
  SUMMARY = 'summary',
}

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    boxShadow: '0px 3px 8px 0px rgba(0,0,0,0.15)',
    borderRadius: 6,
    backgroundColor: theme.customPalette.siteNavigationBackground,
    padding: `${rem(5)} ${rem(5)} ${rem(5)} ${rem(5)}`,
    marginRight: -18,
    marginLeft: -18,
    marginBottom: 23,
    height: 52,
    alignItems: 'center',
    transition: `0.3s`,
    '& .MuiTabs-indicator': {
      height: 3,
      borderRadius: 3,
      bottom: 5,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  tab: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 0,
    paddingTop: 0,
    height: 23,
  },
  tabLabel: {
    color: theme.customPalette.siteNavigationText,
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
  },
  tabPanel: {
    margin: `0 ${rem(10)}`,
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
}))

interface TabPanelProps {
  children: React.ReactNode
  currentValue: TabsEnum
  value: TabsEnum
}

const TabPanel = ({ currentValue, value, children }: TabPanelProps) => {
  const classes = useStyles()
  return (
    <div id={`${value}`} role="tabpanel">
      {value === currentValue && <div className={classes.tabPanel}>{children}</div>}
    </div>
  )
}

interface NavigationProps {
  isTour: boolean
  syncCurrentTab?: (tab: TabsEnum) => void
}

export const Navigation = ({ isTour, syncCurrentTab }: NavigationProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'))
  const [urlParams] = useSearchParams()
  const dispatch = useDispatch()

  const { tournamentSite, selectedRoundIndex } = useSelector((store: StoreState) => store.tournamentReducer)
  const { players, reserveList, teams } = useSelector((store: StoreState) => store.tournamentPlayersReducer)
  const isWeeklyTournament = tournamentSite?.tournament.tournamentType === TournamentTypes.weekly
  const isSummaryPreview = urlParams.get(UrlParamOptions.PREVIEW) === PreviewOptions.SUMMARY

  const [tabsValue, setTabsValue] = useState<TabsEnum>(isTour ? TabsEnum.TOUR_INFO : TabsEnum.INFO)
  const [initialWeeklyTabChangeDone, setInitialWeeklyTabChangeDone] = useState<boolean>(false)

  const leaderboardUrl = APIRoute.GET_LEADERBOARD(tournamentSite?.tournament.id)
  const leaderboardHasScores = useLeaderboardHasScores(leaderboardUrl)

  useEffect(() => {
    if (!isTour) {
      const { rounds, resultsPublished } = tournamentSite.tournament
      const { isCompleted, isConfigured, isScoringDisabled } = rounds[selectedRoundIndex].status
      const isScoringEnabled = isScoringDisabled === false
      const hasSummaryText = tournamentSite?.tournamentSummaryText && tournamentSite?.tournamentSummaryText.length > 0
      const hasSummaryPictures = tournamentSite?.images.summaryPictures.length > 0
      const hasSummaryImage = !!tournamentSite?.images.summaryHeroImage?.id

      let defaultTab: TabsEnum
      switch (true) {
        case resultsPublished && (hasSummaryText || hasSummaryPictures || hasSummaryImage):
        case isSummaryPreview:
          defaultTab = TabsEnum.SUMMARY
          break
        case resultsPublished:
          defaultTab = TabsEnum.RESULTS
          break
        case isScoringEnabled || isCompleted:
          defaultTab = TabsEnum.LEADERBOARD
          break
        case isConfigured && !isWeeklyTournament:
          defaultTab = TabsEnum.STARLIST
          break
        default:
          defaultTab = TabsEnum.INFO
      }

      setTabsValue(defaultTab)
      dispatch(setCurrentPage(defaultTab))
      if (syncCurrentTab) {
        syncCurrentTab(defaultTab)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      tournamentSite?.tournament?.tournamentType === TournamentTypes.weekly &&
      leaderboardHasScores &&
      !initialWeeklyTabChangeDone
    ) {
      setInitialWeeklyTabChangeDone(true)
      setTabsValue(TabsEnum.LEADERBOARD)
      dispatch(setCurrentPage(TabsEnum.LEADERBOARD))
      if (syncCurrentTab) {
        syncCurrentTab(TabsEnum.LEADERBOARD)
      }
    }
  }, [tournamentSite, leaderboardHasScores, initialWeeklyTabChangeDone, dispatch, syncCurrentTab])

  const handleTabChange = (e: ChangeEvent<HTMLElement>, newValue: TabsEnum) => {
    const doc = document.getElementsByClassName('MuiTabs-scroller')[0]

    const tabIndex = Number(e.currentTarget.dataset.index)
    const currentIndex = getTabs().findIndex((tab) => tab.value === tabsValue)

    setTabsValue(newValue)
    dispatch(setCurrentPage(newValue))
    if (syncCurrentTab) {
      syncCurrentTab(newValue)
    }

    // Do not scroll if clicking left or second nav item
    if (currentIndex > tabIndex || tabIndex < 2) {
      return
    }

    let leftValue = e.target.offsetWidth
    if (mediaQuery) {
      if (e.target.offsetWidth + 60 <= e.target.offsetWidth) {
        leftValue = e.target.offsetWidth
      } else {
        leftValue = e.target.offsetWidth + 60
      }
    }

    doc.scrollBy({
      left: leftValue,
      behavior: 'smooth',
    })
  }

  const getEntriesTitle = (): React.ReactNode => {
    if (isTour) {
      return <></>
    }

    // If ENTRIES are by team, we show team count instead of player count.
    // If maximumEntryAmountEnabled is true, we show the maximum amount of players/teams aswell.
    // Reserve list is counted towards the total amount of players/teams: "Players (12/10)"

    const { maximumEntryAmountEnabled, maximumPlayerAmount, maximumTeamAmount, entryMethod } = tournamentSite

    let totalCount = 0
    let maxCount = 0
    let formattedMessageId = ''

    if (entryMethod === EntryMethod.TEAM) {
      totalCount = teams.length // "teams" includes reserve teams
      maxCount = maximumTeamAmount
      formattedMessageId = 'navigation.teams'
    } else {
      totalCount = players.length + reserveList.length
      maxCount = maximumPlayerAmount
      formattedMessageId = 'navigation.players'
    }

    if (totalCount === 0) {
      return <FormattedMessage id={formattedMessageId} />
    }

    if (maximumEntryAmountEnabled && maxCount > 0) {
      return (
        <>
          <FormattedMessage id={formattedMessageId} /> ({totalCount}/{maxCount})
        </>
      )
    }
    return (
      <>
        <FormattedMessage id={formattedMessageId} /> ({totalCount})
      </>
    )
  }

  const hideOnWeeklyTournament = true
  const hideOnMultiRounds = true

  const tabsTournament: ITabs[] = [
    { title: <FormattedMessage id="navigation.info" />, component: <InfoPage />, value: TabsEnum.INFO },
    { title: <FormattedMessage id="navigation.game" />, component: <GamePage />, value: TabsEnum.GAME },
    { title: getEntriesTitle(), component: <PlayersPage />, value: TabsEnum.PLAYERS },
    {
      title: <FormattedMessage id="navigation.startList" />,
      component: <StartListPage />,
      hideOnWeeklyTournament,
      value: TabsEnum.STARLIST,
    },
    {
      title: <FormattedMessage id="navigation.leaderboards" />,
      component: <LeaderboardPage />,
      value: TabsEnum.LEADERBOARD,
    },
    {
      title: <FormattedMessage id="navigation.contests" />,
      component: <ContestsPage />,
      hideOnWeeklyTournament,
      hideOnMultiRounds,
      value: TabsEnum.CONTESTS,
    },
    {
      title: <FormattedMessage id="navigation.results" />,
      component: <ResultsPage />,
      value: TabsEnum.RESULTS,
    },
    {
      title: <FormattedMessage id="navigation.summary" />,
      component: <SummaryPage isPreview={isSummaryPreview} />,
      value: TabsEnum.SUMMARY,
    },
  ]

  const tabsTour: ITabs[] = [
    { title: <FormattedMessage id="navigation.info" />, component: <TourInfo />, value: TabsEnum.TOUR_INFO },
    { title: <FormattedMessage id="navigation.events" />, component: <TourEvents />, value: TabsEnum.TOUR_EVENTS },
    {
      title: <FormattedMessage id="navigation.rankings" />,
      component: <TourRankings />,
      value: TabsEnum.TOUR_RANKINGS,
    },
  ]

  const getTabs = () => {
    switch (true) {
      case isTour:
        return tabsTour
      case isWeeklyTournament:
        return tabsTournament.filter((tab) => !tab.hideOnWeeklyTournament)
      case tournamentSite?.tournament.tournamentType === TournamentTypes.multiRound:
        return tabsTournament.filter((tab) => !tab.hideOnMultiRounds)
      default:
        return tabsTournament
    }
  }

  return (
    <>
      <Tabs
        value={tabsValue}
        variant="scrollable"
        onChange={handleTabChange}
        className={classes.tabs}
        scrollButtons="auto"
        allowScrollButtonsMobile={true}
      >
        {getTabs().map((tab, idx) => (
          <Tab
            key={`tab-${idx}`}
            value={tab.value}
            label={<span className={classes.tabLabel}>{tab.title}</span>}
            className={classes.tab}
            data-index={idx}
            data-testid={`tab-${tab.value}`}
          />
        ))}
      </Tabs>
      {getTabs().map((tab, idx) => (
        <TabPanel key={`tab-panel-${idx}`} value={tab.value} currentValue={tabsValue}>
          {tab.component}
        </TabPanel>
      ))}
    </>
  )
}
