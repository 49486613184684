import React, { CSSProperties } from 'react'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import { Flipped } from 'react-flip-toolkit'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import classNames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    tableRow: {
      backgroundColor: theme.customPalette.tableAltLightBg,
    },
    withBg: {
      backgroundColor: theme.customPalette.tableRowBg,
    },
    expanded: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    darkBg: {
      backgroundColor: theme.customPalette.mediumGray2,
    },
    alternatingRows: {
      '&:nth-child(even)': {
        '@global': {
          td: {
            backgroundColor: theme.customPalette.tableRowBg,
          },
        },
      },
      '@global': {
        '.table-data-borderer': {
          display: 'none',
        },
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  alternatingRows?: boolean
  rowKey?: string
  style?: CSSProperties
  withBg?: boolean
  expanded?: boolean
  screen?: boolean
  darkBg?: boolean
  children: React.ReactNode
  id?: string
}

class TournamentTableRow extends React.Component<Props> {
  render() {
    const {
      children,
      rowKey,
      classes,
      alternatingRows = false,
      style,
      withBg,
      expanded,
      screen,
      darkBg,
      id,
    } = this.props

    const className = classNames(
      classes.tableRow,
      withBg || screen ? classes.withBg : '',
      expanded ? classes.expanded : '',
      darkBg ? classes.darkBg : '',
      { [`${classes.alternatingRows}`]: alternatingRows },
    )
    const rowContents = (
      <tr className={className} style={style} id={id}>
        {children}
      </tr>
    )

    if (!rowKey) {
      return rowContents
    }

    return (
      <Flipped key={rowKey} flipId={rowKey}>
        {rowContents}
      </Flipped>
    )
  }
}

export default withStyles(styles)(TournamentTableRow)
