/* eslint-disable max-len */

export function ClosestToPin({ className = '', style = {} }): JSX.Element {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="34"
      viewBox="0 0 48 34"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.95883 16.5054C0.95883 16.5054 6.38111 14.5119 10.4386 16.7893C12.4054 17.8931 13.7428 19.7345 14.6551 21.5346C16.0582 24.3038 16.4526 27.0007 16.4526 27.0007C16.4915 27.269 16.6986 27.4883 16.9767 27.5555C17.2546 27.6227 17.548 27.5246 17.7189 27.307L19.1657 25.4646C19.3978 25.1692 19.3319 24.7516 19.0191 24.5327C18.7061 24.3136 18.2637 24.3757 18.0316 24.6711L17.5327 25.3064C17.2506 24.189 16.7525 22.586 15.9285 20.9598C14.9027 18.9351 13.372 16.8854 11.16 15.6441C6.5762 13.0713 0.452952 15.2614 0.452952 15.2614C0.0892279 15.3933 -0.0925232 15.7791 0.0469602 16.1223C0.186666 16.4656 0.595328 16.6373 0.95883 16.5054Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1515 17.4895C25.5608 17.4895 27.5166 19.315 27.5166 21.5634C27.5166 23.8121 25.5608 25.6376 23.1515 25.6376C20.7422 25.6376 18.7861 23.8121 18.7861 21.5634C18.7861 19.315 20.7422 17.4895 23.1515 17.4895Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9642 21.2443V22.4209C37.6938 22.8144 39.2243 23.2861 40.5013 23.8204C41.6983 24.3214 42.6585 24.8693 43.3114 25.4706C43.7775 25.9 44.082 26.3336 44.082 26.8107C44.082 27.2877 43.7775 27.7215 43.3114 28.1506C42.6585 28.7522 41.6983 29.2999 40.5013 29.8011C36.5548 31.4529 30.1824 32.5049 23.0031 32.5049C15.824 32.5049 9.45141 31.4529 5.50517 29.8011C4.30788 29.2999 3.34774 28.7522 2.69481 28.1506C2.22875 27.7215 1.92443 27.2877 1.92443 26.8107C1.92443 26.3336 2.22875 25.9 2.69481 25.4706C3.34774 24.8693 4.30788 24.3214 5.50517 23.8204C7.72845 22.8899 10.7219 22.1495 14.1965 21.6806L13.4869 20.6223C5.93608 21.7188 0.708008 24.0789 0.708008 26.8107C0.708008 30.587 10.6981 33.653 23.0031 33.653C35.3081 33.653 45.2982 30.587 45.2982 26.8107C45.2982 24.5174 41.6138 22.4861 35.9642 21.2443ZM33.5633 21.9467C31.9867 21.6791 30.2893 21.4702 28.5025 21.3289L28.1247 20.1499C30.045 20.2887 31.8701 20.5038 33.5633 20.7841V21.9467Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1367 25.6013H35.2997V0.183472H34.1367V25.6013Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0674 6.44591V0.651895C36.6377 -0.193681 39.6381 -0.469756 41.5677 1.40137C43.7954 3.56191 45.3871 3.09808 47.2078 2.76016C45.6601 5.66462 44.1932 5.98786 41.729 5.30712C39.1418 4.5928 37.4035 4.83951 35.0674 6.44591Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8945 22.8651C35.7056 23.2001 41.6088 24.718 41.6088 26.5373C41.6088 28.602 34.0066 30.2782 24.6428 30.2782C15.2789 30.2782 7.67676 28.602 7.67676 26.5373C7.67676 25.2539 10.6141 24.1207 15.0854 23.4468C15.8415 25.4423 16.0773 27.0503 16.0773 27.0503C16.1374 27.4625 16.456 27.7995 16.8831 27.903C17.3105 28.0062 17.7616 27.8551 18.0243 27.5206L19.4712 25.6784C19.6946 25.3941 20.5306 25.5896 21.1068 25.8737C21.7599 26.1954 22.5038 26.3776 23.2931 26.3776C25.5653 26.3776 27.4627 24.8675 27.8945 22.8651Z"
        fill="currentColor"
      />
    </svg>
  )
}
