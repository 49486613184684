import { Theme } from '@mui/material/styles'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { rem } from '../../theme/materialUITheme'
import { Tooltip } from '@mui/material'
import { TooltipProps } from '@mui/material/Tooltip'

const styles = (theme: Theme) =>
  createStyles({
    popper: {},
    tooltip: {
      backgroundColor: '#222A35',
      padding: '4px 12px',
      borderRadius: 10,
      maxWidth: 'none',
      '@global': {
        '.tooltip-text': {
          whiteSpace: 'pre-line',
          fontSize: rem(14),
          lineHeight: 1.1,
          fontFamily: theme.typography.body2.fontFamily,
        },
        '.MuiTooltip-arrow': {
          color: '#222A35',
        },
      },
    },
  })

const CustomTooltip = ({ classes, children, title, ...rest }: WithStyles<typeof styles> & TooltipProps) => (
  <Tooltip
    classes={classes}
    title={<p className={'tooltip-text'}>{title}</p>}
    enterTouchDelay={0}
    leaveTouchDelay={5000}
    {...rest}
  >
    {children}
  </Tooltip>
)

export default withStyles(styles)(CustomTooltip)
