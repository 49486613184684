export function LongestDrive({ className = '', style = {} }): JSX.Element {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 125 125"
      fill="none"
    >
      <path
        fill="currentColor"
        // eslint-disable-next-line max-len
        d="M83.948 22.003c-5.693 2.213-10.81 5.106-14.678 11.303 18.537-3.66 36.276-4.897 54.026.356-19.637 6.29-34.965 17.645-44.412 36.245 8.031-1.437 13.913-6.29 19.795-11.743-1.489 3.722-2.83 7.517-4.498 11.166-5.546 12.141-12.32 23.548-23.81 30.95-14.95 9.625-30.677 11.91-46.802 2.852C12.739 97.04 7.192 87.29 6.144 74.918 4.466 53.153 17.676 37.94 36.139 30.15c15.287-6.417 31.338-8.24 47.81-8.147zM68.222 73.555a26.442 26.442 0 10-26.431 26.526 26.475 26.475 0 0026.42-26.526h.01z"
      ></path>
    </svg>
  )
}
