import React from 'react'
import { connect } from 'react-redux'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import classNames from 'classnames'
import { Theme, Table, TableHead, TableRow, TableCell, Grid, TableBody } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { ClosestToPin, LongestDrive, StraightestDrive } from '@app/assets/images/icons'
import { loadTournamentContestLeaderboard } from '@app/store/tournamentContestLeaderboard/actions'
import { ContestResultType } from '@app/store/tournamentContestLeaderboard/enums'
import { formatMeasurement } from '@app/utils/measurementsUtils'
import { cloneDeep } from 'lodash'

const styles = (theme: Theme) =>
  createStyles({
    contestTypeHeader: {
      backgroundColor: theme.customPalette.primaryExtraDark,
      padding: '0.75em !important',
      marginBottom: '0.1875em',
      borderRadius: '3px',
    },
    contestTypeHeaderSeparator: {
      marginTop: '1em',
    },
    contestTypeIcon: {
      height: '1.5625em',
      width: '2.1875em',
      marginRight: '0.625em',
      color: '#fff',
      float: 'left',
    },
    contestTypeTitle: {
      color: '#fff',
      fontSize: '1.125em',
      fontWeight: 700,
    },
    contestTable: {
      marginBottom: '0.1875em',

      '& th, & td': {
        border: 0,
      },
      '& th': {
        backgroundColor: theme.customPalette.mediumGray2,
        padding: '0.25em 0 0.25em 0.625em !important',
        borderRadius: '3px',
      },
      '& td': {
        border: 0,
        padding: '0.625em !important',
        backgroundColor: theme.customPalette.tableAltLightBg,
        fontWeight: 700,
        color: theme.customPalette.textColor,
        fontSize: '0.875em',
        wordBreak: 'break-all',

        '&:first-child': {
          borderRadius: '3px 0 0 3px',
          width: '1.5em',
        },
        '&:last-child': {
          borderRadius: '0 3px 3px 0',
          fontWeight: 'bold',
          textAlign: 'right',
          whiteSpace: 'nowrap',
        },
      },
    },
    hole: {
      color: '#fff',
      fontWeight: 700,
      fontSize: '0.875em',
    },
  })

interface OwnProps extends WithStyles<typeof styles> {
  tournamentId?: number
}

interface StateProps {
  contests?: ContestResult[]
  tournamentSite: TournamentSite
}

interface DispatchProps {
  loadTournamentContestLeaderboard(id: number): void
}

type Props = OwnProps & StateProps & DispatchProps

interface State {
  scoreExpanded?: string
}

class ContestsDataScreen extends React.Component<Props, State> {
  timer = null

  public componentDidMount(): void {
    const { tournamentId, loadTournamentContestLeaderboard } = this.props

    if (tournamentId) {
      this.props.loadTournamentContestLeaderboard(tournamentId)
    }

    // Refresh leaderboard every 30 seconds
    this.timer = setInterval(() => loadTournamentContestLeaderboard(tournamentId), 30000)
  }

  public componentWillUnmount(): void {
    clearInterval(this.timer)
  }

  render() {
    const contests = this.props.contests ? cloneDeep(this.props.contests) : null
    let prevType = undefined

    if (contests) {
      return (
        <Grid container>
          {contests
            .sort(
              (a, b) =>
                Object.values(ContestResultType).indexOf(a.type) - Object.values(ContestResultType).indexOf(b.type) ||
                a.holeNumber - b.holeNumber,
            )
            .map((c) => {
              const renderTypeHeader = prevType !== c.type
              prevType = c.type
              return this._renderHole(c.type, c.holeNumber.toString(), c.entries, c.measured, renderTypeHeader)
            })}
        </Grid>
      )
    }
  }

  private _renderHole = (
    type: ContestResultType,
    hole: string,
    result: ContestEntry[],
    measured: boolean,
    renderTypeHeader: boolean,
  ) => {
    const { classes, tournamentSite } = this.props
    const r = result.shift()

    return (
      <Grid item xs={12} md={12} lg={12} key={`${type}-${hole}`}>
        {renderTypeHeader && (
          <div
            className={classNames([
              classes.contestTypeHeader,
              Object.values(ContestResultType).indexOf(type) > 0 ? classes.contestTypeHeaderSeparator : undefined,
            ])}
          >
            {this._getTitle(type)}
          </div>
        )}
        <Table className={classes.contestTable}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} className={classes.hole}>
                <FormattedMessage id="leaderboard.hole" /> {hole}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {r && r.score && (
              <TableRow>
                <TableCell>{r.position}.</TableCell>
                <TableCell>
                  {r.user.firstName} {r.user.lastName}
                </TableCell>
                <TableCell>{measured && formatMeasurement(r.score, tournamentSite.units)}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    )
  }

  private _getTitle = (type: string) => {
    const { classes } = this.props

    if (type === ContestResultType.CLOSEST_TO_PIN) {
      return (
        <>
          <ClosestToPin className={classes.contestTypeIcon} />
          <span className={classes.contestTypeTitle}>
            <FormattedMessage id="leaderboard.closestToPin" />
          </span>
        </>
      )
    } else if (type === ContestResultType.LONGEST_DRIVE) {
      return (
        <>
          <LongestDrive className={classes.contestTypeIcon} style={{ height: '1.875em' }} />
          <span className={classes.contestTypeTitle}>
            <FormattedMessage id="leaderboard.longestDrive" />
          </span>
        </>
      )
    } else if (type === ContestResultType.STRAIGHTEST_DRIVE) {
      return (
        <>
          <StraightestDrive className={classes.contestTypeIcon} style={{ height: '1.875em' }} />
          <span className={classes.contestTypeTitle}>
            <FormattedMessage id="leaderboard.straightestDrive" />
          </span>
        </>
      )
    }
  }
}

export default withStyles(styles)(
  connect<StateProps, DispatchProps, {}, StoreState>(
    (state) => ({
      contests: state.tournamentContestLeaderboardReducer.data?.rounds[0]?.contests,
      tournamentSite: state.tournamentReducer.tournamentSite,
    }),
    { loadTournamentContestLeaderboard },
  )(ContestsDataScreen),
)
