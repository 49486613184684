import React, { useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Theme } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import { FormattedMessage, useIntl } from 'react-intl'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { Slider, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { APIRoute } from '@app/config'
import { setActiveDivision, setActiveDivisionEndpoint } from '@app/store/tournament/actions'
import { SelectBoxMenuItem } from '../ui/SelectBox'

const DEFAULT_SCROLLING_SPEED = 2
const rollingSpeedOptions = [
  { value: 1, label: '1x' },
  { value: 2, label: '1.5x' },
  { value: 3, label: '2x' },
]

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: '0.625em',
    marginTop: '-0.175em',
  },
  menuButton: {
    fontSize: '1em',
  },
  menuIcon: {
    fontSize: '2.1875em',
  },
  menuItem: {
    paddingLeft: '0.625em',
    paddingRight: '1em',
    paddingTop: '0.1875em',
    paddingBottom: '0.1875em',
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    fontSize: '0.75em',

    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)',
    },

    '&.Mui-selected': {
      backgroundColor: theme.customPalette.success,

      '&:hover': {
        backgroundColor: theme.customPalette.success,
      },
    },
  },
  menuTitle: {
    paddingLeft: '0.625em',
    paddingRight: '1em',
    paddingTop: '0.1875em',
    paddingBottom: '0.1875em',
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    fontSize: '0.75em',
  },
  paper: {
    backgroundColor: theme.customPalette.mediumGray2,
    fontSize: '16px',

    '@media only screen and (min-width: 1500px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width: 1900px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width: 2500px)': {
      fontSize: '22px',
    },
    '@media only screen and (min-width: 3800px)': {
      fontSize: '32px',
    },
  },
  on: {
    color: theme.customPalette.success,
  },
  off: {
    color: '#ff3737',
  },
  sliderContainer: {
    '& .MuiSlider-thumb, & .MuiSlider-track, & .MuiSlider-rail, & .MuiSlider-active': {
      color: '#fff',
    },
    '& .MuiSlider-markLabel': {
      color: '#fff',
      fontSize: '0.75em',
    },
  },
}))

export default function ScreenMenu(props: {
  selectedGameType: string
  setSelectedGameType: (name: string) => void
  rolling: boolean
  setRolling: (value: boolean) => void
  rollingSpeed: number
  setRollingSpeed: (value: number) => void
  setIsMenuOpen: (value: boolean) => void
  sideGameEnabled: boolean
  gameModeNames: {
    primary: string
    side: string
  }
}) {
  const intl = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { tournamentSite, activeDivision } = useSelector((state: StoreState) => state.tournamentReducer)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const divisions = useMemo(() => {
    if (tournamentSite.divisions.length === 0) return []

    const items: SelectBoxMenuItem[] = tournamentSite.divisions.map((division) => ({
      id: String(division.id),
      label: division.name,
      value: APIRoute.GET_LEADERBOARD_WITH_DIVISIONS(tournamentSite.tournament.id, division.id),
    }))

    items.unshift({
      id: '0',
      label: intl.formatMessage({ id: 'leaderboard.allPlayers' }),
      value: APIRoute.GET_LEADERBOARD(tournamentSite.tournament.id),
    })

    return items
  }, [intl, tournamentSite])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    props.setIsMenuOpen(true)
    props.setRollingSpeed(DEFAULT_SCROLLING_SPEED)
  }

  const handleClose = () => {
    setAnchorEl(null)
    props.setIsMenuOpen(false)
  }

  const handleGameTypeChange = (value: string) => {
    props.setSelectedGameType(value)
    handleClose()
  }

  const handleRollingToggle = () => {
    props.setRolling(!props.rolling)
  }

  const handleSpeedChange = (e: React.ChangeEvent<HTMLElement>, value: number): void => {
    e.stopPropagation()
    props.setRollingSpeed(value)
  }

  const changeLeaderboardDivision = (division: SelectBoxMenuItem) => {
    dispatch(setActiveDivision(Number(division.id)))
    dispatch(setActiveDivisionEndpoint(String(division.value)))
    handleClose()
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="more"
        aria-controls="screen-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: 0 }}
        size="large"
        className={classes.menuButton}
      >
        <MenuIcon htmlColor={'#fff'} fontSize={'large'} className={classes.menuIcon} />
      </IconButton>
      <Menu
        id="screen-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        slotProps={{ paper: { className: classes.paper } }}
      >
        <Typography variant="h6" className={classes.menuTitle}>
          <FormattedMessage id="leaderboard.gameType" />
        </Typography>
        <MenuItem
          selected={props.selectedGameType === 'primary'}
          onClick={() => handleGameTypeChange('primary')}
          className={classes.menuItem}
        >
          <ArrowRight fontSize={'small'} />
          {props.gameModeNames['primary']}
        </MenuItem>
        {props.sideGameEnabled && (
          <MenuItem
            selected={props.selectedGameType === 'side'}
            onClick={() => handleGameTypeChange('side')}
            className={classes.menuItem}
          >
            <ArrowRight fontSize={'small'} />
            {props.gameModeNames['side']}
          </MenuItem>
        )}

        {!!divisions.length && (
          <div>
            <Typography variant="h6" className={classes.menuTitle}>
              <FormattedMessage id="leaderboard.division" />
            </Typography>
            {divisions.map((division) => {
              return (
                <MenuItem
                  key={division.id}
                  selected={Number(division.id) === activeDivision}
                  className={classes.menuItem}
                  onClick={() => changeLeaderboardDivision(division)}
                >
                  <ArrowRight fontSize={'small'} />
                  {division.label}
                </MenuItem>
              )
            })}
          </div>
        )}

        <Typography variant="h6" className={classes.menuTitle}>
          <FormattedMessage id="leaderboard.rolling" />
        </Typography>
        <MenuItem onClick={handleRollingToggle} className={classes.menuItem}>
          {props.rolling ? <ArrowDropDown fontSize={'small'} /> : <ArrowRight fontSize={'small'} />}
          <FormattedMessage id="leaderboard.rolling" />
          &nbsp;&nbsp;
          <span className={props.rolling ? classes.on : classes.off}>
            <FormattedMessage id={props.rolling ? 'leaderboard.on' : 'leaderboard.off'} />
          </span>
        </MenuItem>
        {props.rolling && (
          <MenuItem onClick={(e) => e.stopPropagation()} className={classes.sliderContainer}>
            <Slider
              value={props.rollingSpeed}
              step={null}
              marks={rollingSpeedOptions}
              onChangeCommitted={handleSpeedChange}
              min={1}
              max={3}
            />
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}
