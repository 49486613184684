import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import {
  Theme,
  TableCell,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import { loadTournamentPlayers } from '../../store/tournamentPlayers/actions'
import TournamentTableRow from '../table/TournamentTableRow'
import TournamentTableCell from '../table/TournamentTableCell'
import moment from 'moment'
import TournamentTable, { TournamentTableHead, TournamentTableBody } from '../table/TournamentTable'
import { FormattedMessage } from 'react-intl'
import { TableSortLabel } from '@mui/material'
import { defaultFormat } from '../../utils/date'
import { formatHandicap } from '@app/utils/playerUtils'
import { EntryMethod } from '@app/utils/enums'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.customPalette.tableTextColor,
    fontFamily: ['Public sans', 'sans-serif'].join(','),
  },
  flag: {
    display: 'block',
    margin: '0 auto',
    width: 24,
  },
  tableHeader: {
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    fontFamily: ['Public sans', 'sans-serif'].join(','),
    fontSize: theme.typography.pxToRem(13),
    padding: `4px 7px 4px`,
    '&:first-of-type': {
      paddingLeft: 10,
    },
    '&:last-of-type': {
      paddingRight: 20,
    },
  },
  tableCell: {
    '&:first-of-type': {
      paddingLeft: 10,
    },
    '&:last-of-type': {
      paddingRight: 20,
    },
  },
  tableRow: {
    backgroundColor: '#555555',
    height: 41,
    width: '50%',
  },
  select: {
    color: 'white',
    border: '1px solid white',
    borderRadius: 6,
    height: 32,
    width: '100%',
    maxWidth: 162,
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  smallContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
  },
  playerName: {
    display: 'flex',
    flexDirection: 'column',
  },
  extraInfo: {
    fontWeight: 'normal',
    fontSize: 10,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '80%',
  },
  hcp: {
    fontWeight: 400,
  },
  nameCell: {
    fontWeight: 400,
  },
}))

interface Props {
  type?: string
  tournamentId?: number
}

export const LeaderboardPlayers = ({ type, tournamentId }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.down('md'))

  const { loading, players, reserveList } = useSelector((store: StoreState) => store.tournamentPlayersReducer)
  const { tournamentSite } = useSelector((store: StoreState) => store.tournamentReducer)
  const { isTeamFormat } = tournamentSite.tournament

  const [sortBy, changeSort] = useState<string>(
    isTeamFormat && tournamentSite.onlineEntriesEnabled && tournamentSite.entryMethod === EntryMethod.TEAM
      ? 'teamName'
      : 'name',
  )
  const [direction, changeDirection] = useState<'asc' | 'desc'>('desc')
  const defaultSelection = isTeamFormat ? 'teamName' : 'entryDate'
  const [selection, changeSelection] = useState<string>(defaultSelection)

  useEffect(() => {
    tournamentId && dispatch(loadTournamentPlayers(tournamentId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const sortByValue =
      type === 'reserveList'
        ? 'entryDate'
        : isTeamFormat && tournamentSite.onlineEntriesEnabled && tournamentSite.entryMethod === EntryMethod.TEAM
        ? 'teamName'
        : 'name'
    changeSort(sortByValue)
    changeDirection('desc')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const _renderSelectedRow = (player) => {
    switch (selection) {
      case 'teamName':
        return (
          <TournamentTableCell className={classes.tableCell}>
            {isTeamFormat ? player.team?.name : player.club ? player.club.name : '-'}
          </TournamentTableCell>
        )
      case 'entryDate':
        return (
          <TournamentTableCell className={classes.tableCell}>
            {moment(player.entryTime).format(defaultFormat('date', tournamentSite.units))}
          </TournamentTableCell>
        )
      default:
        return (
          <TournamentTableCell className={classes.tableCell}>
            {isTeamFormat ? player.team?.name : player.club ? player.club.name : '-'}
          </TournamentTableCell>
        )
    }
  }

  const _renderRow = (player: Player, idx: number) => {
    return (
      <TournamentTableRow alternatingRows key={`${player.id}_${idx}`} rowKey={`${player.id}`}>
        {!isTeamFormat && tournamentSite.countryFlagForPlayersEnabled && (
          <TournamentTableCell className={classes.tableCell}>
            {player.countryCode && (
              <img
                src={`//static.golfgamebook.com/flags/${player.countryCode}.png`}
                alt={player.country}
                className={classes.flag}
              />
            )}
          </TournamentTableCell>
        )}
        <TournamentTableCell className={classNames([classes.tableCell, classes.nameCell])}>
          <div className={classes.playerName}>
            <span id="name">
              {player.firstName} {player.lastName}
            </span>
            {mediaQuery && (
              <div className={classes.extraInfo}>
                <span className={classes.hcp}>HCP</span> {formatHandicap(String(player.hcp))}
                &nbsp;&nbsp;|&nbsp;&nbsp;
                {player.club ? player.club.name : '-'}
              </div>
            )}
          </div>
        </TournamentTableCell>
        {!mediaQuery ? (
          <>
            <TournamentTableCell className={classes.tableCell}>
              {isTeamFormat ? player.team?.name : player.club ? player.club.name : '-'}
            </TournamentTableCell>
            <TournamentTableCell className={classes.tableCell}>
              {formatHandicap(String(player.hcp))}
            </TournamentTableCell>
            <TournamentTableCell className={classes.tableCell}>
              {moment(player.entryTime).format(defaultFormat('date', tournamentSite.units))}
            </TournamentTableCell>
          </>
        ) : (
          _renderSelectedRow(player)
        )}
      </TournamentTableRow>
    )
  }

  const _warpWithSorting = (sortByKey: string, content: any) => {
    return (
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortBy === sortByKey ? direction : 'desc'}
        onClick={() => _sortTable(sortByKey, direction)}
      >
        {content}
      </TableSortLabel>
    )
  }

  const _sortTable = (newSortBy: string, newDirection: 'asc' | 'desc') => {
    let dir: 'asc' | 'desc' = 'desc'
    if (sortBy === newSortBy) {
      dir = newDirection === 'desc' ? 'asc' : 'desc'
    }
    changeSort(newSortBy)
    changeDirection(dir)
  }

  const _sortPlayers = (p: Player[]) => {
    const sortedPlayers = p.slice().sort((a, b) => {
      switch (sortBy) {
        case 'name':
          return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
        case 'teamName':
          if (!a.team?.name || !b.team?.name) return 0
          return a.team.name.localeCompare(b.team.name)
        case 'homeClub':
          if (!a.club?.name || !b.club?.name) return 0
          return a.club.name.localeCompare(b.club.name)
        case 'hcpIndex':
          let hcpA = a.hcp
          let hcpB = b.hcp
          if (hcpA.toString().startsWith('+')) hcpA = -hcpA
          if (hcpB.toString().startsWith('+')) hcpB = -hcpB
          return hcpA - hcpB
        case 'entryDate':
          return moment(a.entryTime).unix() - moment(b.entryTime).unix()
      }
      return 0
    })

    if (direction === 'asc') sortedPlayers.reverse()

    return sortedPlayers
  }

  const maybeRenderMobileSelect = (): JSX.Element => {
    if (!isTeamFormat) {
      return _warpWithSorting('entryDate', <FormattedMessage id="leaderboard.entryDate" />)
    }
    return (
      <Select id="value-select" value={selection} onChange={handleSelectionChange} className={classes.select}>
        <MenuItem value="teamName">
          <FormattedMessage id="leaderboard.teamName" />
        </MenuItem>
        <MenuItem value="entryDate">
          <FormattedMessage id="leaderboard.entryDate" />
        </MenuItem>
      </Select>
    )
  }

  const handleSelectionChange = (e: SelectChangeEvent) => {
    changeSelection(e.target.value)
  }

  const showCountry = !isTeamFormat && tournamentSite.countryFlagForPlayersEnabled
  const playersList = type === 'reserveList' ? _sortPlayers(reserveList) : _sortPlayers(players)

  if (loading) {
    return (
      <div className={classes.smallContainer}>
        <CircularProgress size={48} thickness={2} />
      </div>
    )
  }

  return (
    <>
      {playersList.length === 0 ? (
        <div className={classes.smallContainer}>
          <FormattedMessage id="strings.emptyList" />
        </div>
      ) : (
        <TournamentTable className={classes.root} arraySortKey={playersList.map((p) => p.id).join('')}>
          <TournamentTableHead>
            <TournamentTableRow style={{ backgroundColor: '#555555', height: 41, width: '50%' }}>
              {showCountry && (
                <TableCell variant="head" style={{ width: 40, paddingLeft: 20 }} className={classes.tableHeader}>
                  <FormattedMessage id="leaderboard.country" />
                </TableCell>
              )}
              <TableCell
                variant="head"
                sortDirection={sortBy === 'name' ? direction : false}
                className={classes.tableHeader}
              >
                {_warpWithSorting('name', <FormattedMessage id="leaderboard.name" />)}
              </TableCell>
              {!mediaQuery ? (
                <>
                  <TableCell
                    variant="head"
                    sortDirection={sortBy === 'teamName' || sortBy === 'homeClub' ? direction : false}
                    className={classes.tableHeader}
                  >
                    {isTeamFormat
                      ? _warpWithSorting('teamName', <FormattedMessage id="leaderboard.teamName" />)
                      : _warpWithSorting('homeClub', <FormattedMessage id="leaderboard.homeClub" />)}
                  </TableCell>
                  <TableCell
                    variant="head"
                    sortDirection={sortBy === 'hcpIndex' ? direction : false}
                    className={classes.tableHeader}
                  >
                    {_warpWithSorting('hcpIndex', <FormattedMessage id="leaderboard.hcpIndex" />)}
                  </TableCell>
                  <TableCell
                    variant="head"
                    sortDirection={sortBy === 'entryDate' ? direction : false}
                    className={classes.tableHeader}
                  >
                    {_warpWithSorting('entryDate', <FormattedMessage id="leaderboard.entryDate" />)}
                  </TableCell>
                </>
              ) : (
                <TableCell variant="head" className={classes.tableHeader}>
                  {maybeRenderMobileSelect()}
                </TableCell>
              )}
            </TournamentTableRow>
          </TournamentTableHead>
          <TournamentTableBody>{playersList.map((player, i) => _renderRow(player, i))}</TournamentTableBody>
        </TournamentTable>
      )}
    </>
  )
}
