import React from 'react'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.2,
  },
  values: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 1.2,
  },
}))

interface Props {
  gameOptions?: GameOptions
  gameFormatId: number
}

const GameOptions = ({ gameOptions, gameFormatId }: Props) => {
  const classes = useStyles()
  return gameOptions ? (
    <tbody>
      <tr>
        <td>
          <Typography variant={'body1'} className={classes.title}>
            <FormattedMessage id={'strings.gameFormat'} />:
          </Typography>
        </td>
        <td>
          <Typography variant={'body1'} className={classes.values}>
            <FormattedMessage id={`gameFormats.type${gameFormatId}`} />
          </Typography>
        </td>
      </tr>
      {gameOptions?.countBestResults && (
        <tr>
          <td></td>
          <td>
            <Typography variant={'body1'} className={classes.values}>
              <b>
                <FormattedMessage id={`gameFormats.countBestResults_${gameOptions.countBestResults}`} />
              </b>
            </Typography>
          </td>
        </tr>
      )}
      <tr>
        <td>
          <Typography variant={'body1'} className={classes.title}>
            <FormattedMessage id={'strings.useHandicaps'} />:
          </Typography>
        </td>
        <td>
          <Typography variant={'body1'} className={classes.values}>
            <b>
              {gameOptions.useHandicaps ? <FormattedMessage id="buttons.yes" /> : <FormattedMessage id="buttons.no" />}
            </b>
          </Typography>
        </td>
      </tr>
      {gameOptions.useHandicaps && (
        <tr>
          <td>
            <Typography variant={'body1'} className={classes.title}>
              <FormattedMessage id={'strings.handicapPercentage'} />:
            </Typography>
          </td>
          <td>
            <Typography variant={'body1'} className={classes.values}>
              <b>{gameOptions.handicapPercentage}</b>
            </Typography>
          </td>
        </tr>
      )}
      {!!gameOptions?.modifier && (
        <tr>
          <td>
            <Typography variant={'body1'} className={classes.title}>
              <FormattedMessage id={'strings.modifier'} />:
            </Typography>
          </td>
          <td>
            <Typography variant={'body1'} className={classes.values}>
              <b style={{ textTransform: 'capitalize' }}>{gameOptions.modifier.replaceAll('_', ' ')}</b>
            </Typography>
          </td>
        </tr>
      )}
    </tbody>
  ) : null
}

export default GameOptions
