import { Measurements } from './enums'

export const formatMeasurement = (measurement: number, units: string): string => {
  if (measurement < 0) return ''

  if (measurement > 2000) {
    if (units === 'metric') {
      return `${Math.round(measurement * 0.0254)}${Measurements.METERS}`
    } else {
      return `${Math.round(measurement / 36)}${Measurements.YARDS}`
    }
  } else {
    if (units === 'metric') {
      const meters = measurement * 0.0254
      if (1 > meters) {
        return `${Math.round(meters * 100)}${Measurements.CENTIMETERS}`
      }
      return `${meters.toFixed(2)}${Measurements.METERS}`
    } else {
      return `${Math.floor(measurement / 12)}${Measurements.FEET} ${Math.round(measurement % 12)}${Measurements.INCHES}`
    }
  }
}
