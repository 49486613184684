/* eslint-disable max-len */

export function StraightestDrive({ className = '', style = {} }): JSX.Element {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 125 125"
      fill="none"
    >
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="9.528" d="M33.624 75.168L33.624 13.764"></path>
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="9.528" d="M61.15 113.282L61.15 75.169"></path>
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="9.528" d="M88.676 26.469L88.676 13.764"></path>
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="9.528" d="M88.676 75.168L88.676 49.76"></path>
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="9.528" d="M35.741 75.168L88.676 75.168"></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M114.084 36.526c6.72 0 12.176 5.456 12.176 12.175 0 6.72-5.456 12.175-12.176 12.175-6.719 0-12.174-5.455-12.174-12.175 0-6.72 5.455-12.175 12.174-12.175z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        d="M2.216 54.288s3.682-4.1 6.313-6.146c2.485-1.934 2.795-2.19 6.629-4.59 3.833-2.4 4.077-2.357 6.84-3.553 2.776-1.202 7.31-2.568 7.31-2.568l1.266 6.225s-8.343 2.04-13.535 3.834c-5.977 2.066-14.823 6.798-14.823 6.798zM41.035 34.409c10.057-2.117 16.41-4.234 29.643-4.235 13.234 0 32.29 6.882 32.29 6.882s-1.058 0-3.176 2.647c-2.117 2.647-2.117 5.293-2.117 5.293s-8.999-3.705-10.587-4.234c-1.588-.53-6.352-2.648-13.234-3.177-7.965-.613-9.528-.457-18.527.53-8.999.986-14.292 3.176-14.292 3.176v-6.882z"
      ></path>
    </svg>
  )
}
